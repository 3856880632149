export const routeNames: { [key: string]: string } = {
  contact: '/contact-us',
  careers: '/careers',
  workingAtSyberry: '/working-at-syberry',
  vacancy: '/careers/vacancy',
  home: '/',
  whatWeDo: '/what-we-do',
  finance: '/industries/finance',
  healthcare: '/industries/healthcare',
  energy: '/industries/energy',
  startups: '/industries/startups',
  technology: '/industries/technology',
  automotive: '/industries/automotive',
  education: '/industries/education',
  construction: '/industries/construction',
  compliance: '/industries/compliance',
  erp: '/industries/erp',
  apply: '/careers/vacancy/apply',
  aboutUs: '/about',
  ourWork: '/our-work',
  insights: '/insights',
  caseStudies: '/case-studies',
  energyManagement: '/case-studies/energy-management-platform-vs-utility-bills',
  servicesCSD: '/what-we-do/custom-software-development',
  servicesLSM: '/what-we-do/legacy-software-modernization',
};

export const PAGE_NOT_FOUND_SEGMENT = '__DEFAULT__';
