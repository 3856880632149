'use client';

import { CaseCard, ICaseStudyCardData } from '@/components/Cards/CaseCard';
import { Lens } from './Lens';
import { ArrowButtonLink } from '@/components/Links/ArrowButtonLink';
import clsx from 'clsx';

interface IProps {
  data: ICaseStudyCardData[];
  link: {
    to: string;
    title: string;
  };
  withButton?: boolean;
  withLens?: boolean;
  titleSlot?: boolean;
  wrapperClassName?: string;
  purpleView?: boolean;
  textContent?: string;
  isExpandable?: boolean;
}

const getCardBackgroundColor = (purpleView = false, isViewAllCard = false) => {
  const viewAllColor = purpleView ? 'bg-green-9' : 'bg-purple-9';
  const nonViewAllColor = purpleView ? 'bg-purple-15' : 'bg-purple-14';

  return isViewAllCard ? viewAllColor : nonViewAllColor;
};

const getCardCornerColor = (purpleView = false, isViewAllCard = false) => {
  const viewAllColor = purpleView ? '#239745' : '#862963';
  const nonViewAllColor = purpleView ? '#943175' : '#3A275A';

  return isViewAllCard ? viewAllColor : nonViewAllColor;
};

const getTitleColor = (purpleView = false, isViewAllCard = false) => {
  const viewAllColor = purpleView ? 'text-grey-9' : 'text-green-7';
  const nonViewAllColor = purpleView ? 'text-green-7' : 'text-purple-7';

  return isViewAllCard ? viewAllColor : nonViewAllColor;
};

export const Cases = ({
  data,
  link,
  withButton = true,
  withLens = true,
  titleSlot = false,
  wrapperClassName,
  purpleView = false,
  isExpandable = false,
}: IProps) => (
  <div
    className={clsx(
      `bg-black-11 flex flex-col justify-center relative overflow-x-clip gap-[40px]
        mobile:gap-[55px] items-center pt-[40px] pb-[40px] mobile:pb-[80px] px-[16px]`,
      {
        [`${wrapperClassName}`]: wrapperClassName,
      },
    )}
  >
    {titleSlot && (
      <div className="p-base flex justify-center w-full">
        <div className="w-full max-w-[1376px]">
          <h3 className="intro-pre-title mb-xs !mb-0 !text-green-7">Our Projects</h3>
        </div>
      </div>
    )}
    <div
      className="
        relative justify-center max-w-[1376px] w-full md-tablet:w-auto lg-2:w-full gap-[28px]
        grid grid-cols-1 md-tablet:grid-cols-2 lg-2:grid-cols-3
      "
    >
      {data.map(({ id, ...rest }) => (
        <CaseCard
          {...rest}
          id={id}
          key={id}
          animated
          purpleView={purpleView}
          isExpandable={isExpandable}
          isViewAllCard={id === 'all'}
          getTitleColor={getTitleColor}
          getCardCornerColor={getCardCornerColor}
          getCardBackgroundColor={getCardBackgroundColor}
        />
      ))}
    </div>
    {withLens && <Lens />}
    {withButton && (
      <ArrowButtonLink
        arrowVariant="secondary"
        gradientPosition="!h-[1000%]"
        gradient="bg-conic-gradient-green"
        buttonTextClassname="!font-normal"
        buttonClassname="text-white flex mt-[32px] lg:mt-0 whitespace-nowrap
        w-[133.77px] h-[53.02px] text-[17.67px] leading-[17.67px]
        mobile:w-[418px] mobile:h-[48px] mobile:text-[16px] mobile:leading-[22px] border-white50"
        to={link.to}
      >
        {link.title}
      </ArrowButtonLink>
    )}
  </div>
);
