import { LoopingVideo } from '@/components/LoopingVideo';
import Link from 'next/link';
import { routeNames } from '@/constants/routes';
import { useRef, useState } from 'react';
import { AnimationBlock } from '@/components/AnimationBlock';
import { Arrow } from '@/images/svg/Arrow';
import Image from 'next/image';
import clsx from 'clsx';

export interface ICaseStudyCardData {
  id: string;
  title: string;
  tags: {
    title: string;
  }[];
  description: string;
  video?: string | null;
  image?: {
    path: string;
    width: number;
    height: number;
    alt?: string;
  } | null;
  href: string;
  textContent?: string;
}

type TCardClassName = (purpleView: boolean | undefined, isViewAllCard: boolean) => string;

export interface ICaseStudyCard extends ICaseStudyCardData {
  animated?: boolean;
  purpleView?: boolean;
  getCardBackgroundColor: TCardClassName;
  getCardCornerColor: TCardClassName;
  getTitleColor: TCardClassName;
  isViewAllCard: boolean;
  isExpandable: boolean;
}

export const CaseCard = ({
  description,
  title,
  tags,
  href,
  animated = false,
  video,
  id,
  image,
  purpleView,
  getCardBackgroundColor,
  getCardCornerColor,
  getTitleColor,
  isViewAllCard,
  textContent,
  isExpandable = false,
}: ICaseStudyCard) => {
  const titleClassName = getTitleColor(purpleView, isViewAllCard);
  const backgroundClassName = getCardBackgroundColor(purpleView, isViewAllCard);
  const blockRef = useRef(null);
  const [isVideoPlaying, setVideoPlay] = useState(false);

  const card = (
    <Link
      href={`${href}#nav-header`}
      ref={blockRef}
      className="group flex justify-center"
    >
      <div
        onMouseOver={() => video && setVideoPlay(true)}
        onMouseLeave={() => video && setVideoPlay(false)}
        className={clsx(
          `
          min-h-[583px] flex flex-col max-w-[440px] w-full relative group
          cursor-pointer hover:scale-[1.05] duration-300 overflow-hidden rounded-[20px]
          shadow-lg z-[10]
        `,
          {
            [`${backgroundClassName}`]: backgroundClassName,
          },
        )}
      >
        <div
          className={clsx(
            `
            h-[289px] w-full
          `,
            {
              [`bg-black-11`]: isViewAllCard,
            },
          )}
        >
          {video && (
            <LoopingVideo
              src={video}
              className="!z-[1] !m-0 !w-full !aspect-auto"
              innerClassName="!aspect-auto"
              isPlaying={isVideoPlaying}
            />
          )}
          {image && (
            <Image
              src={image.path}
              width={image.width}
              height={image.height}
              alt={image.alt || 'Logo'}
              loading="lazy"
              placeholder="empty"
              className={clsx(`object-cover w-full h-full`, {
                [`group-hover:opacity-0 duration-[1000ms]`]: isExpandable && !isViewAllCard,
              })}
            />
          )}
        </div>
        <div
          className={clsx(
            `
            flex flex-col px-[24px] pt-[24px] absolute top-0 left-0
            translate-y-[289px] z-10
          `,
            {
              [`${backgroundClassName}`]: backgroundClassName,
              [`group-hover:translate-y-[-2px] duration-500`]: isExpandable && !isViewAllCard,
            },
          )}
        >
          <div
            className={clsx(
              `
              w-full h-[87px] absolute top-0 translate-y-[-87px] z-0 
            `,
              {
                [`group-hover:translate-y-0 duration-500`]: isExpandable && !isViewAllCard,
              },
            )}
          >
            <svg
              width="140"
              height="87"
              viewBox="0 0 140 87"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="absolute bottom-[-2px] right-[18px]"
            >
              <path
                d="M140 0V87H0L140 0Z"
                fill={getCardCornerColor(purpleView, isViewAllCard)}
              />
            </svg>
          </div>

          <div
            className={clsx('leading-[24px] text-base uppercase font-medium mb-[12px] relative z-10', {
              [`${titleClassName}`]: titleClassName,
            })}
          >
            {title}
          </div>
          {description && (
            <p
              className="
              text-grey-1 leading-[35px] text-[30px] mobile:text-4xl
              mobile:leading-[43.2px] mb-[20px] relative z-10 line-clamp-3
            "
            >
              {description}
            </p>
          )}
          {textContent && (
            <div
              className="
              leading-[25.6px] text-base opacity-0 line-clamp-[12]
              group-hover:opacity-[100] duration-500 text-white relative z-10
            "
            >
              {textContent}
            </div>
          )}
        </div>
        <div className="flex-1 w-full" />
        <div
          className={clsx(
            `
            w-full px-[24px] py-[32px] flex justify-between relative z-10
          `,
            {
              [`${backgroundClassName}`]: backgroundClassName,
            },
          )}
        >
          <div className="flex justify-between items-center">
            {tags && (
              <object className="flex gap-[8px] flex-wrap">
                {tags.map((tag) => {
                  return (
                    <Link
                      key={tag.title}
                      href={{
                        pathname: routeNames.ourWork,
                        query: { category: tag.title, page: 1 },
                      }}
                    >
                      <span
                        className="
                        transition-colors duration-300 ease-out text-[14px]
                        flex items-center justify-center bg-transparent
                        rounded-full border-[1px] px-[10px] py-[1px]
                        text-center hover:bg-white/10
                        text-white border-white
                        leading-[20px]
                      "
                      >
                        {tag.title}
                      </span>
                    </Link>
                  );
                })}
              </object>
            )}
          </div>
          <div className="opacity-[0] group-hover:opacity-[100] duration-500 flex items-center">
            <span
              className={clsx('', {
                [`text-white`]: purpleView,
                [`text-green-7`]: !purpleView,
              })}
            >
              Expand
            </span>
            <Arrow
              variant={`${purpleView ? 'secondary' : 'home-green'}`}
              className="group-hover:translate-x-[5px] duration-300"
            />
          </div>
        </div>
      </div>
    </Link>
  );

  return (
    <>
      {animated ? (
        <AnimationBlock
          id={`card-anim-${id}`}
          blockRef={blockRef}
        >
          {card}
        </AnimationBlock>
      ) : (
        card
      )}
    </>
  );
};
